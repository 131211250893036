<template>
  <mcb-detail class="prescription" @esc="cancelHandle" @failure="failureHandle" @success="successHandle" :readOnly="readOnly">
    <template v-slot:photo>
      <div class="photo">
        <img :src="'data:image/png;base64, ' + item" v-for="(item, key) in photo" :key="key" />
      </div>
    </template>

    <template v-slot:default>
      <el-form label-width="110px" label-position="left">
        <el-form-item label="Клиника">
          <el-autocomplete class="clinic" v-model="clinic" :fetch-suggestions="searchClinic" placeholder="Клиника" :readOnly="readOnly" />
        </el-form-item>
        <el-form-item label="Дата">
          <el-date-picker class="date" v-model="date" type="date" placeholder="Дата" value-format="yyyy-MM-dd" format="dd MMMM yyyy" :readOnly="readOnly" />
        </el-form-item>
        <el-form-item label="Специальность">
          <el-autocomplete class="speciality" v-model="speciality" :fetch-suggestions="searchSpeciality" placeholder="Специальность" :readOnly="readOnly" />
        </el-form-item>
        <el-form-item label="Диагноз">
          <mcb-multiple-values v-model="diagnoses">
            <template slot-scope="scope">
              <el-autocomplete class="diagnose" :value="scope.item" @input="addList(diagnoses, scope.index, $event)" :fetch-suggestions="searchDiagnose" placeholder="Диагноз" />
            </template>
          </mcb-multiple-values>
        </el-form-item>
        <el-form-item label="Препараты">
          <mcb-multiple-values v-model="drugs">
            <template slot-scope="scope">
              <el-autocomplete class="drug" :value="scope.item" @input="addList(drugs, scope.index, $event)" :fetch-suggestions="searchDrug" placeholder="Препараты" />
            </template>
          </mcb-multiple-values>
        </el-form-item>
        <el-form-item label="Причина" prop="reason" v-if="reason && readOnly">
          <el-input type="textarea" :rows="4" v-model="reason" :readOnly="readOnly" />
        </el-form-item>
      </el-form>
    </template>
  </mcb-detail>
</template>

<script>
import DetailPage from '@/mixins/detail-page.js'
import { PRESCRIPTION_RECOGNIZED_PRESCRIPT, PRESCRIPTION_RECOGNIZED } from '@/components/utils.js'

export default {
  mixins: [DetailPage],

  data () {
    return {
      photo: [],
      clinic: '',
      date: '',
      speciality: '',
      drugs: [''],
      diagnoses: [''],
      reason: ''
    }
  },

  beforeMount () {
    this.loadPrescriptData()
  },

  computed: {
    readOnly () {
      return this.mutableValue.status == PRESCRIPTION_RECOGNIZED_PRESCRIPT || this.mutableValue.status >= PRESCRIPTION_RECOGNIZED
    }
  },

  methods: {
    loadPrescriptData () {
      this.$wait.show()
      this.$axios.post('/prescription/' + this.mutableValue.id + '/prescription')
        .then(({data}) => {
          this.clinic = data.clinic || ''
          this.date = data.date || ''
          this.speciality = data.speciality || ''
          this.reason = data.reason || ''
          this.diagnoses = data.diagnoses || ['']
          this.drugs = data.drugs || ['']
          this.photo = data.photo || []
        })
        .catch(err => {
          console.error(err)
          if (err.response.data.message) {
            this.$notify.error({title: 'Error',  message: err.response.data.message})
          }
        })
        .finally(() => {
          this.$wait.hide()
        })
    },

    trimName (name) {
      return ( name || '' ).replace( /^\s+|\s+$/g, '' )
    },

    addList (arr, index, value) {
      arr[index] = value
      const x = arr.filter(item => !!item.trim()).filter((item, pos, self) => self.indexOf(item) == pos )
      if (x.length < 10) {
        x.push('')
      }
      arr.splice(0, arr.length, ...x)
    },

    searchName (t, name, cb) {
      name = this.trimName(name)
      if (name) {
        this.$axios.get('completion/' + t + '?name=' + name)
          .then(response => {
            cb(response.data)
          })
          .catch(err => {
            console.log(err)
            cb([])
          })
      } else {
        cb([])
      }
    },

    searchClinic (name, cb) {
      this.searchName('clinic', name, cb)
    },

    searchSpeciality (name, cb) {
      this.searchName('speciality', name, cb)
    },

    searchDiagnose (name, cb) {
      this.searchName('diagnose', name, cb)
    },
  
    searchDrug (name, cb) {
      this.searchName('drug', name, cb)
    },

    failureHandle (val) {
      const url = '/prescription/' + this.mutableValue.id + '/failure'
      this.sendData(url, { reason: val })
    },

    successHandle () {
      const data = {
        clinic: this.clinic,
        date: this.date,
        speciality: this.speciality,
        diagnoses: this.diagnoses,
        drugs: this.drugs        
      }
      if (data.date && data.diagnoses.length && data.drugs.length) {
        const url = '/prescription/' + this.mutableValue.id + '/prescription/success'
        this.sendData(url, data)
      }
    },

    sendData (url, data) {
      this.$axios.post(url, data)
        .then(({data}) => {
          this.$set(this.mutableValue, 'status', data.status)
          this.$emit('input', this.mutableValue)
        })
        .catch(err => {
          console.error(err)
          if (err.response.data.message) {
            this.$notify.error({title: 'Error',  message: err.response.data.message})
          }
        })
    }
  }
}
</script>

<style lang="less">
@import "../style.less";

.prescription {
  .el-autocomplete, .el-date-editor, .el-textarea {
    width: 100%;
  }

  .photo {
    flex: auto;
    background-color: @gray1-color;   
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
    }
  }

  .clinic input {
    border: 1px solid red;
  }

  .date input {
    border: 1px solid blue;
  }

  .speciality input {
    border: 1px solid #E6A23C;
  }

  .diagnose input {
    border: 1px solid green;
    margin-bottom: 10px;
  }

  .drug input {
    border: 1px solid #8B008B;
    margin-bottom: 10px;
  }
}
</style>
